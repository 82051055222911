import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import styled from 'styled-components';
import {
  Drawer,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { useHistory } from 'react-router-dom';
import { withFirebase } from '../Firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: 'black',
  },
  login: {
    color: 'black',
  },
  drawerItem: {
    '&:hover': {
      background: 'black',
      color: 'white',
    },
  },
  drawerText: {
    fontWeight: 500,
    fontSize: 16,
  },
}));

const CustomAppBar = styled(AppBar)`
  box-shadow: none;
  background: white;
  border-bottom: 1px solid black;
`;

const drawerData = [
  { name: 'Home', link: '/' },
  { name: 'Locations', link: '/locations' },
  { name: '3D Content', link: '/editor' },
];

const CustomDrawer = withStyles((theme) => ({
  paper: {
    top: 64,
    borderTop: `1px solid black`,
    borderRight: `1px solid black`,
    left: (props) => (props.open === true ? 0 : -250),
    transition: 'left .4s',
    width: 250,
  },
}))(Drawer);

const Header = ({ firebase }) => {
  const { currentUser } = firebase.auth;
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const handleClickAway = () => setOpen(false);
  const handleMenuClick = () => setOpen(!open);
  const handleSignOut = () => firebase.doSignOut();
  const handleSignInClick = () => history.push('/signin');
  const [user, setUser] = useState({});

  const classes = useStyles(open);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <CustomAppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              aria-label="menu"
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Omniscape World Map
            </Typography>
            {firebase.auth.currentUser ? (
              <Button onClick={handleSignOut} className={classes.login} color="inherit">
                Log out
              </Button>
            ) : (
              <Button onClick={handleSignInClick} className={classes.login} color="inherit">
                Login
              </Button>
            )}
          </Toolbar>
        </CustomAppBar>
        <CustomDrawer
          transitionDuration={250}
          variant="permanent"
          open={open}
          onClose={handleClickAway}
          className={classes.drawer}
        >
          <List>
            {drawerData.map((item) => (
              <ListItem
                button
                onClick={() => {
                  handleMenuClick();
                  history.push(item.link);
                }}
                key={item.name}
                className={classes.drawerItem}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ className: classes.drawerText }}
                  primary={item.name}
                />
              </ListItem>
            ))}
          </List>
        </CustomDrawer>
      </div>
    </ClickAwayListener>
  );
};

export default withFirebase(Header);
