import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyD6DNepwkW5Xlr1N7ZUbGwTr-0cUygkAT0',
  authDomain: 'omniscapexr.firebaseapp.com',
  databaseURL: 'https://omniscapexr.firebaseio.com',
  projectId: 'omniscapexr',
  storageBucket: 'omniscapexr.appspot.com',
  messagingSenderId: '595962636469',
  appId: '1:595962636469:web:e4465f9ee33f36ee0db6fc',
  measurementId: 'G-T5PR81HP6E',
};

// Initialize Firebase

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.firestore = app.firestore();
  }

  /**
   *
   *  *** AUTH API ***
   *
   * /

  /**
   * Allows a user to sign in a user who is signed up with Firebase
   * @param  {} email
   * @param  {} password
   */
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  /**
   * Creates a new user in Firebase
   * @param  {} email
   * @param  {} password
   */
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  /**
   * Signs out an authenticated user
   * @param  {} =>this.auth.signOut(
   */
  doSignOut = () => this.auth.signOut();

  /**
   * Save structures to firebase
   */
  saveCity = (cityData) =>
    this.firestore.collection('city').doc('city').set(cityData, { merge: true });

  saveLocations = (hexes) =>
    this.firestore.collection('city').doc('locations').set(hexes, { merge: true });

  loadCity = () => this.firestore.collection('city').doc('city').get();
}
export default Firebase;
