import React, { Suspense } from 'react';
import './App.css';
import {withAuthentication} from "./components/Session";
import Header from './components/Header'
import Loading from './components/Loading'

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
const SignIn = React.lazy(() => import('./components/SignIn'))
const SignUp = React.lazy(() => import('./components/SignUp'))
const Viewer = React.lazy(() => import('./components/Viewer'))
const Locations = React.lazy(() => import("./components/Locations"));
const EditorV2 = React.lazy(() => import("./components/EditorV2"));
const VirtualGoods = React.lazy(() => import('./components/VirtualGoods'))

function App(props) {
  return (
    <div>
      <Router>
        <Header />
        <Switch>
          <Suspense fallback={<Loading />}>
            <Route path="/editor">
              <EditorV2 />
            </Route>
            <Route path="/locations">
              <Locations />
            </Route>
            <Route path="/goods">
              <VirtualGoods/>
            </Route>
            <Route path="/signin">
              <SignIn />
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>            
            <Route exact path="/">
              <Viewer />
            </Route>
          </Suspense>
        </Switch>
      </Router>
    </div>
  );
}

export default withAuthentication(App);
