import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = (Component) => {
  const WithAuthenticationBase = (props) => {
    const [authUser, setAuthUser] = useState({});
    const { firebase } = props;

    useEffect(() => {
      firebase.auth.onAuthStateChanged(async (auth) => {
        if (auth) {
          return (setAuthUser(auth));
        }
        return setAuthUser(null);
      });
    }, [firebase, firebase.auth, props]);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component props={props} />
      </AuthUserContext.Provider>
    );
  };

  return compose(
    withFirebase,
  )(WithAuthenticationBase);
};


export default withAuthentication;
