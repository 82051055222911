/* eslint-disable no-underscore-dangle */
import { createStore } from "redux";
import rootReducer from "../reducers";

// connect middlewares to the store with third argument
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
